interface KitVariant {
  quantity: number
  name: string
}

export const createKitVariantString = (kitVariants: KitVariant[]): string => {
  return kitVariants
    .map((kitVariant) => {
      const amount = kitVariant.quantity > 1 ? `(${kitVariant.quantity})` : ""
      return `${kitVariant.name}` + amount
    })
    .join(", ")
}
